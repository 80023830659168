import React from 'react';
import '../index.scss';
import {Logo} from "../components/Logo";
import {SignIn} from "../components/SignIn";
import {Link} from 'gatsby';
import {Page} from "../components/Page";

const SignInPage = () => {
  return <Page title='Sign In'>
    <section>
      <Link to='/' className='no-style'><Logo/></Link>
      <div className='signup'>
        <SignIn/>
      </div>
    </section>
  </Page>
};

export default SignInPage;
